.inquiryBox {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	background-color: #146060;
	width: 70%;
	margin: 50px auto;
	padding: 30px;
	border: none;
	border-radius: 8px;
}
.inquiryBox h2 {
	font-family: var(--accentFont);
	font-size: 40px;
	color: var(--yellow);
}
.inquiryBox h3 {
	letter-spacing: 3px;
}
.inquiryBox button {
	padding: 15px 20px;
	border-radius: 8px;
	border: none;
	background-color: var(--yellow);
	box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);
	margin: 20px;
}
.inquiryBox .linkButton {
	text-decoration: none;
	color: var(--green);
}
.inquiryBox button:hover {
	opacity: 0.7;
	cursor: pointer;
}
@media only screen and (max-width: 700px) {
	.inquiryBox {
		flex-direction: column;
	}
}
