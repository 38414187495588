@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;800&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap");

:root {
	font-family: "Inter", sans-serif;
	--accentFont: "Libre Baskerville", serif;

	color: #faf5e4;
	--green: #004445;
	--lightgreen: #2c786c;
	--yellow: #f8b400;
	--orange: #f79902;
}

.App {
	text-align: center;
}
body {
	padding: 0;
	margin: 0;
}
