.projects {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: var(--lightgreen);
}
h1 {
	font-size: 60px;
	font-family: var(--accentFont);
	letter-spacing: 5px;
	padding: 80px 0 50px 0;
	color: var(--yellow);
}
.tracking-in-expand {
	animation: tracking-in-expand 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) both;
}
@keyframes tracking-in-expand {
	0% {
		letter-spacing: -0.5em;
		opacity: 0;
	}
	40% {
		opacity: 0.6;
	}
	100% {
		opacity: 1;
	}
}
.projectItem {
	max-width: 70vw;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 30px;
	padding: 20px;
	margin: 30px;
	background-color: var(--green);
	box-shadow: 10px 10px 20px rgba(79, 76, 67, 0.2);
	border: 3px double #faf5e4;
}
#toLeft {
	text-align: left;
	border-radius: 200px 5px 5px 200px;
}
#toRight {
	flex-direction: row-reverse;
	text-align: right;
	border-radius: 5px 200px 200px 5px;
}
.projectItem .projPic {
	max-height: 250px;
	border: 3px double #faf5e4;
	border-radius: 7x;
	margin: 30px;
}
.projectItem .info {
	height: 250px;
	flex-grow: 1;
	color: #faf5e4;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 30px;
}
.projectItem .info h2 {
	font-size: 25px;
	font-weight: 100;
	text-transform: uppercase;
	font-family: var(--accentFont);
	line-height: 2em;
}
.projectItem .info p {
	font-size: 20px;
	font-weight: 100;
}
.projectItem .links {
	display: inline;
}
.projectItem .link {
	font-size: 30px;
	color: #faf5e4;
	transition-duration: 0.2s;
	padding: 10px;
}
.projectItem .link:hover {
	color: var(--lightgreen);
}
@media only screen and (max-width: 1000px) {
	.projectItem .projPic {
		max-width: 300px;
	}
}
@media only screen and (max-width: 900px) {
	.projectItem .projPic {
		max-width: 250px;
	}
}
@media only screen and (max-width: 800px) {
	h1 {
		font-size: 50px;
	}
	.projectItem {
		flex-direction: column;
		gap: 10px;
	}
	#toLeft {
		text-align: center;
		border-radius: 5px;
	}
	#toRight {
		flex-direction: column;
		text-align: center;
		border-radius: 5px;
	}
}
