.home {
	width: 100%;
}
body {
	background-color: var(--green);
}
.hero {
	height: 60vh;
	padding: 100px 50px;
	text-align: left;
	background: var(--green);
	font-family: var(--accentFont);
}

.hero svg {
	stroke: var(--yellow);
	stroke-width: 2;
	width: 500px;
}

.hero text {
	font-size: 80px;
	font-weight: 700;
	letter-spacing: 5px;
	stroke-dasharray: 500;
	stroke-dashoffset: 500;
	animation: dash 5s linear forwards, filling 4s ease-in forwards;
}

@keyframes dash {
	to {
		stroke-dashoffset: 0;
	}
}

@keyframes filling {
	from {
		fill: var(--yellow);
		fill-opacity: 0;
	}
	to {
		fill: var(--yellow);
		fill-opacity: 1;
	}
}
.hero h2 {
	font-size: 40px;
	font-weight: 100;
	color: #faf5e4;
	padding-left: 20px;
}
.hero h3 {
	font-size: 22px;
	color: var(--orange);
	padding-left: 20px;
}
@media only screen and (max-width: 600px) {
	.hero {
		text-align: center;
	}
	.hero h2 {
		font-size: 40px;
	}
	.hero text {
		font-size: 50px;
	}
	.hero h3 {
		line-height: 45px;
	}
	.hero svg {
		width: 350px;
	}
}

main {
	background-color: var(--lightgreen);
	padding: 80px 50px;
}
main .title {
	font-size: 60px;
	font-family: var(--accentFont);
	letter-spacing: 5px;
	padding: 30px;
	margin-top: 50px;
}
main .projectPreview {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 40px;
	padding: 30px 0;
	margin: 0 auto;
	max-width: 800px;
}
main .projectPreview img {
	border-radius: 6px;
	box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);
}
main .projectPreview div {
	padding: 10px;
	font-weight: 200;
}
main .more {
	background-color: var(--lightgreen);
	padding: 15px 20px;
	border-radius: 8px;
	border: 1px #faf5e4 solid;
	display: block;
	margin: 0 auto;

	font-weight: 800;
}
main .more .linkButton {
	color: #faf5e4;
	text-decoration: none;
}
button:hover {
	opacity: 0.7;
	cursor: pointer;
}

main ul {
	list-style-type: none;
	padding: 0;
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;
	max-width: 800px;
}
main li {
	background-color: var(--orange);
	color: var(--green);
	padding: 16px;
	border-radius: 6px;
	font-size: 20px;
	font-weight: 800;
	box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 750px) {
	main .title {
		font-size: 40px;
	}
	main .projectPreview {
		grid-template-columns: repeat(1, 1fr);
	}
	main .projectPreview img {
		width: 300px;
	}
	main ul {
		grid-template-columns: repeat(2, 1fr);
		max-width: 300px;
	}
	main li {
		font-size: 15px;
	}
}
