.footer {
	height: 100px;
	margin: 50px;
	font-weight: 100;
}
.footer p {
	line-height: 36px;
}
.footer .linkIcons {
	font-size: 20px;
	display: flex;
	max-width: 20vw;
	justify-content: space-evenly;
	margin: 10px auto;
}
.footer a {
	text-decoration: none;
	color: white;
}

.footer a:hover {
	color: var(--yellow);
}
