.about {
	background-color: var(--green);
	padding: 50px;
	line-height: 2;
}

.about h2 {
	font-family: "Inter", sans-serif;
	color: #faf5e4;
}
.about p {
	max-width: 70vw;
	margin: 0 auto;
	font-weight: 200;
}
.about .name {
	color: var(--orange);
	font-size: 40px;
}
.about h3 {
	padding: 30px;
	font-size: 30px;
	margin-top: 50px;
}
.about .techs {
	display: flex;
	justify-content: space-around;
	max-width: 50vw;
	margin: 0 auto;
	font-size: 30px;
	padding-bottom: 50px;
}
